<template>
	<el-dialog v-model="addCardShow" @close="handleClose" title="添加海鲜卡" width="45%">
		<el-form label-width="100px" :model="addForm" :rules="addFormRules" ref="hospitalFormRef">
			<el-form-item label="商品名称" prop="goods_name">
				<el-input placeholder="请输入商品名称" v-model="addForm.goods_name" />
			</el-form-item>
			<el-form-item label="商品规格" prop="spc">
				<el-input placeholder="请输入商品规格" v-model="addForm.spc" />
			</el-form-item>
			<el-form-item label="上传详情图片" prop="imageUrl">
				<el-input placeholder="请输入商品规格" v-model="addForm.imageUrl" style="display: none;" />
				<el-upload  :action="UPLOAD_PICTURE_URL" :show-file-list="false"
					:headers="headers" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
					style="display: block!important;">
					<div class="img" v-if="addForm.imageUrl" style="width: 100%;">
						<img :src="'https://hxdh.yslts.com/' + addForm.imageUrl" class="avatar" style="width: 100%;" />
					</div>
					<div  v-else class="btn" style="width: 100%;">
						<el-icon class="avatar-uploader-icon" style="width: 100px;height: 100px;border:1px solid #eee">
							<Plus />
						</el-icon>
					</div>
				</el-upload>
			</el-form-item>
			<div class="text-red" style="color:#f00;font-size: 12px;display: block;">
				照片推荐: 1MB 以下的 PNG , JPEG , JPG 格式的图片
			</div>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="handleClose"> 取消</el-button>
				<el-button type="primary" @click="onOkBtn"> 确认</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		computed,
		defineProps,
		defineEmits,
		watch
	} from 'vue'
	import {
		addGoodsAPI
	} from '@/api/card'
	import {
		UPLOAD_PICTURE
	} from '@/api/rotation'
	import {
		useVModel
	} from '@vueuse/core'
	import {
		ElMessage
	} from 'element-plus'
	import {
		userStore
	} from '@/store'
	/**
	 *  v-model
	 */
	const props = defineProps({
		modelValue: {
			type: Boolean,
			required: true
		},

		editorial: {
			type: Object
		},

		onListData: {
			type: Function
		}
	})
	defineEmits(['update:modelValue', 'editorial']) // 注册 v-model 事件
	const addCardShow = useVModel(props) // 得到一个响应式的数据，可以直接修改

	/**
	 *  v-model
	 */

	watch(
		() => props.editorial,
		(val) => {
			addForm.value = val
		}
	)

	/**
	 *  表单 S
	 */
	// 表单数据源
	const addForm = ref({
		goods_name: '',
		imageUrl: '',
		spc: ''
	})

	// 验证规则
	const addFormRules = ref({
		goods_name: [{
			required: true,
			trigger: 'blur',
			message: '商品名称必填'
		}],
		spc: [{
			required: true,
			trigger: 'blur',
			message: '商品规格必填'
		}],
		imageUrl: [{
			required: true,
			trigger: 'blur',
			message: '商品详情必填'
		}]
	})

	// 取消
	const handleClose = () => {
		addCardShow.value = false
		hospitalFormRef.value.resetFields()
		addForm.value = {}
		props.onListData()
	}

	// 确认
	// 获得 form 实例
	const hospitalFormRef = ref(null)
	const onOkBtn = () => {
		hospitalFormRef.value.validate(async (valid) => {
			if (!valid) return
			try {
				await addGoodsAPI(addForm.value)
				ElMessage.success('商品添加成功')
				props.onListData()
			} catch (error) {
				console.log(error)
			} finally {
				addCardShow.value = false
			}
		})
	}
	const onSuccess = async (results) => {
		try {
			// loading.value = true // 开始导入时显示loading
			console.log(results)
			// console.log(res)
			ElMessage.success({
				message: results.msg,
				type: results.code == 0 ? 'success' : 'error',
				duration: 1000
			})
		} catch (error) {
			console.error(error)
			ElMessage.error({
				message: '上传失败',
				type: 'error',
				duration: 1000
			})
		}
	}


	// action 路径
	const UPLOAD_PICTURE_URL = ref(UPLOAD_PICTURE)
	// token 追加
	const store = userStore()
	const headers = computed(() => {
		return {
			Authorization: `Bearer ${store.token}`
		}
	})

	// 上传之后
	const handleAvatarSuccess = (response, uploadFile) => {
		addForm.value.imageUrl = response.data.path
		console.log(addForm.value.imageUrl)
	}
	// 上传之前
	const beforeAvatarUpload = (rawFile) => {
		const types = ['image/jpeg', 'image/png', 'image/jpg']
		if (!types.includes(rawFile.type)) {
			ElMessage.error('上传的图片只能是 PNG , JPEG , JPG 的格式')
			return false
		} else if (rawFile.size / 1024 / 1024 > 2) {
			ElMessage.error('图片最大不可以超过 2MB!')
			return false
		}
		return true
	}
	/**
	 *  表单 E
	 */
</script>

<style lang="scss" scoped>
	.el-upload{
		display: block!important;
	}
</style>