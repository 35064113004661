<template>
  <!-- 添加编辑 -->
  <el-dialog v-model="dialogShow" @close="btnCancel" title="编辑商品" width="45%">
  	<el-form label-width="100px" :model="form" :rules="nameRules" ref="levelRef">
  		<el-form-item label="商品名称" prop="goods_name">
  			<el-input placeholder="请输入商品名称" v-model="form.goods_name" />
  		</el-form-item>
  		<el-form-item label="商品规格" prop="spc">
  			<el-input placeholder="请输入商品规格" v-model="form.spc" />
  		</el-form-item>
  		<el-form-item label="上传详情图片" prop="imageUrl">
  			<el-input placeholder="请输入商品规格" v-model="form.imageUrl" style="display: none;" />
  			<el-upload  :action="UPLOAD_PICTURE_URL" :show-file-list="false"
  				:headers="headers" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
  				style="display: block!important;">
  				<div class="img" v-if="form.imageUrl" style="width: 100%;">
  					<img :src="'https://hxdh.yslts.com/' + form.imageUrl" class="avatar" style="width: 100%;" />
  				</div>
  				<div  v-else class="btn" style="width: 100%;">
  					<el-icon class="avatar-uploader-icon" style="width: 100px;height: 100px;border:1px solid #eee">
  						<Plus />
  					</el-icon>
  				</div>
  			</el-upload>
  		</el-form-item>
  		<div class="text-red" style="color:#f00;font-size: 12px;display: block;">
  			照片推荐: 1MB 以下的 PNG , JPEG , JPG 格式的图片
  		</div>
  	</el-form>
  	<template #footer>
  		<span class="dialog-footer">
  			<el-button @click="btnCancel"> 取消</el-button>
  			<el-button type="primary" @click="btnOk"> 确认</el-button>
  		</span>
  	</template>
  </el-dialog>
</template>

<script setup>
import { defineProps, computed, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { saveGoodsdAPI } from '@/api/card'
import {
		userStore
	} from '@/store'
import {
		UPLOAD_PICTURE
	} from '@/api/rotation'
import { ElMessage } from 'element-plus'
const props = defineProps({
  // 接收 v-model
  modelValue: {
    type: Boolean,
    required: true
  },
  onListData: {
    type: Function
  },

  cardinfo: {
    type: Object
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const dialogShow = useVModel(props) // 得到一个响应式的数据，可以直接修改
watch(
  () => props.cardinfo,
  (val) => {
    form.value = val
  },
  {
    deep: true
  }
)

const form = ref({
 goods_name: '',
 imageUrl: '',
 spc: ''
})

const nameRules = ref({
  goods_name: [{
  	required: true,
  	trigger: 'blur',
  	message: '商品名称必填'
  }],
  spc: [{
  	required: true,
  	trigger: 'blur',
  	message: '商品规格必填'
  }],
  imageUrl: [{
  	required: true,
  	trigger: 'blur',
  	message: '商品详情必填'
  }]
})

// 获得 Ref 实例
const levelRef = ref(null)
// 取消
const btnCancel = () => {
  dialogShow.value = false
}

// 确认
const btnOk = () => {
  levelRef.value.validate(async (valid) => {
    if (!valid) return
    try {
      // 触发添加 api
      console.log(Number(form.value.id))
      if (Number(form.value.id)) {
        await saveGoodsdAPI(form.value)
        ElMessage.success('产品名称修改成功。')
      }
      props.onListData()
    } catch (error) {
      console.log(error)
    } finally {
      dialogShow.value = false
    }
  })
}


// action 路径
	const UPLOAD_PICTURE_URL = ref(UPLOAD_PICTURE)
	// token 追加
	const store = userStore()
	const headers = computed(() => {
		return {
			Authorization: `Bearer ${store.token}`
		}
	})

	// 上传之后
	const handleAvatarSuccess = (response, uploadFile) => {
		form.value.imageUrl = response.data.path
		console.log(form.value.imageUrl)
	}
	// 上传之前
	const beforeAvatarUpload = (rawFile) => {
		const types = ['image/jpeg', 'image/png', 'image/jpg']
		if (!types.includes(rawFile.type)) {
			ElMessage.error('上传的图片只能是 PNG , JPEG , JPG 的格式')
			return false
		} else if (rawFile.size / 1024 / 1024 > 2) {
			ElMessage.error('图片最大不可以超过 2MB!')
			return false
		}
		return true
	}
</script>

<style></style>
