// goods
// 海鲜卡-商品管理
import layout from '@/layout/index.vue'
export default {
  path: '/',
  component: layout,
  redirect: '/rotation',
  name: 'goods',
  children: [
    {
      path: '/goods',
      name: 'goods',
      component: () => import('@/views/goods/index.vue'),
      meta: {
        title: '商品管理',
        icon: 'guanyu'
      }
    },
    {
      path: '/goods/detail/:id',
      name: 'goodsDetail',
      component: () => import('@/views/goods/compont/detail.vue'),
      meta: {
        title: '商品详情'
      }
    },
    {
      path: '/goods/import',
      name: 'goodsImport',
      component: () => import('@/views/goods/import/index'),
      meta: {
        title: 'excel导入'
      }
    }
  ]
}
