<template>
	<div>
		<div>
			<el-form>
				<el-row :gutter="20">
					<el-col :span="5">
						<el-form-item label="收货姓名">
							<el-input v-model="search.name" placeholder="请输入收货人姓名">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="收货手机号">
							<el-input v-model="search.phone" placeholder="请输入收货人手机号">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="请选择发货状态">
							<el-select placeholder="请选择发货状态" v-model="search.delivery_status">
								<el-option label="待发货" :value="0" />
								<el-option label="已发货" :value="1" />
								<el-option label="已完成" :value="2" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="物流单号">
							<el-input v-model="search.kd_code" placeholder="请输入物流单号">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="2">
						<el-button type="primary" @click="onDetail"> 搜索 </el-button>
					</el-col>
				</el-row>
			</el-form>

			<!-- 表格 -->
			<div class="table">
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column prop="card_number" label="卡号" width="90px" />
					<el-table-column prop="card_times" label="第几次发货" width="100px" />
					<!-- <el-table-column prop="id" label="ID" /> -->
					<el-table-column prop="delivery_time" label="发货时间" width="100px" />
					<el-table-column prop="week" label="星期几" width="70px" />
					<el-table-column prop="name" label="收货姓名" width="80px" />
					<el-table-column prop="phone" label="收货手机号" width="90px" />
					<el-table-column prop="address" label="收货地址" width="250px" />
					<el-table-column label="发货状态">
						<template v-slot="{ row }">
							{{ row.delivery_status==0 ? '待发货' : row.delivery_status==1 ? '已发货' : '已完成'}}
						</template>
					</el-table-column>
					<el-table-column prop="kd_name" label="物流公司" />
					<el-table-column prop="kd_code" label="物流单号" width="150px" />
					<!-- <el-table-column prop="kd_time" label="发出时间" width="150px"/> -->
					<el-table-column prop="add_time" label="添加时间" width="150px" />
					<el-table-column label="计划状态">
						<template v-slot="{ row }">
							{{ row.is_stop ? '暂停' : '正常' }}
						</template>
					</el-table-column>
					<el-table-column label="操作" width="180" fixed="right">
						<template #default="{ row }">
							<el-button type="primary" size="small" @click="onEditNameBtn(row)"
								v-if="row.delivery_status === 0 && row.is_stop === 0 && row.isdate === 0">
								发货
							</el-button>
							<el-button type="success" size="small" @click="getWlBtn(row)"
								v-if="row.delivery_status > -1">
								查看物流
							</el-button>
							<span v-if="row.is_stop === 1" style="color: red;">已暂停发货</span>
							<span v-if="row.delivery_status === 0 && row.is_stop===0 && row.isdate === 1"
								style="color:blue">未到发货时间</span>
							<!-- <el-button type="primary" size="small" @click="onEditNameBtn(row)"
                         >发货</el-button
                       > -->
						</template>
					</el-table-column>
				</el-table>

				<!-- 分页 -->
				<el-row style="margin-top: 20px">
					<el-col>
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:page-sizes="[10, 20, 50, 100]" :page-size="search.limit"
							layout="total, sizes, prev, pager, next, jumper" :total="search.total">
						</el-pagination>
					</el-col>
				</el-row>
			</div>
		</div>
		<!-- // 发货信息-->
		<deliver v-model="editstaffname" :deliverinfo="deliverinfo" :onDetail="onDetail"></deliver>
		<!-- 物流信息 -->
		<deliverDetail v-model="showWlInfo" :deliverinfo="wlInfo" :onDetail="onDetail"></deliverDetail>
	</div>
</template>

<script setup>
	import {
		detailAPI,
		cardDetailsAPI
	} from '@/api/card'
	import {
		useRoute
	} from 'vue-router'
	import {
		ref
	} from 'vue'
	import deliver from './deliver.vue'
	import deliverDetail from './deliverDetail.vue'
	const route = useRoute()

	const search = ref({
		page: 1, // 当前页
		limit: 10, // 每页显示条数
		total: 0
	})

	/** 表格 S */
	// 定义起始的虚拟序号
	// const virtualIdStart = ref(1)

	// 定义一个计算属性来计算虚拟序号
	// const virtualId = computed(() => {
	//   return (search.value.page - 1) * search.value.limit + 1
	// })
	const tableData = ref([])
	// 在更新表格数据时，为每一行添加虚拟序号
	// const updateTableData = () => {
	//   tableData.value.forEach((item, index) => {
	//     item.virtualId = virtualId.value + index
	//   })
	// }
	// 更新虚拟序号的函数
	// const updateVirtualId = () => {
	//   tableData.value.forEach((item) => {
	//     item.virtualId = virtualId.value // 使用相同的虚拟序号
	//   })
	// }
	// 在 onDetail 函数中调用 updateVirtualId 函数，更新虚拟序号
	const onDetail = async () => {
		const res = await cardDetailsAPI({
			id: route.params.id
		})
		search.value.id = res.id

		const data = await detailAPI(search.value)
		search.value.total = data.total
		tableData.value = data.data

		// // 更新表格中的虚拟序号
		// updateTableData()
	}
	onDetail()

	// 修改姓名
	const editstaffname = ref(false)
	const deliverinfo = ref({})
	const onEditNameBtn = (row) => {
		deliverinfo.value = row
		console.log(deliverinfo.value)
		editstaffname.value = true
	}


	// 查询物流信息
	const showWlInfo = ref(false)
	const wlInfo = ref({})
	const getWlBtn = (row) => {
		wlInfo.value = row
		console.log(wlInfo.value)
		showWlInfo.value = true
	}
	// const detailShow = ref(false)
	// // 编辑
	// const moneyItem = ref({})
	// const onDetailtBtn = (row) => {
	//   moneyItem.value = row
	//   detailShow.value = true
	// }

	// 每页获取多少数据
	const handleSizeChange = (val) => {
		search.value.limit = val
		onDetail()
	}

	// 页码值
	const handleCurrentChange = (val) => {
		search.value.page = val
		onDetail()
		// 更新虚拟序号
		// updateVirtualId()
	}
	/**
	 *  分页 E
	 */
</script>

<style lang="scss" scoped>
	.table {
		margin-top: 20px;
	}
</style>
