<template>
	<!-- 添加编辑 -->
	<el-dialog title="物流信息" width="56%" v-model="dialogShow">

    <div class="allInfo">
      <div class="item" v-for="(item,index) in wlData">
        <p>{{item.time}}</p>
        <span>{{item.context}}</span>
      </div>
    </div>

		<template #footer>
			<span class="dialog-footer">
				<el-button @click="btnCancel"> 取消 </el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		defineProps,
		defineEmits,
		ref,
		watch
	} from 'vue'
	import {
		useVModel
	} from '@vueuse/core'
	import {
		wlSendInfoAPI, wlInfoAPI
	} from '@/api/card'
	import {
		ElMessage
	} from 'element-plus'
	const props = defineProps({
		// 接收 v-model
		modelValue: {
			type: Boolean,
			required: true
		},
		onDetail: {
			type: Function
		},

		deliverinfo: {
			type: Object
		}
	})
	defineEmits(['update:modelValue']) // 注册 v-model 事件
	const dialogShow = useVModel(props) // 得到一个响应式的数据，可以直接修改
	watch(
		() => props.deliverinfo,
		(val) => {
			form.value = val
       onWlData()
		}, {
			deep: true
		}
	)

  const form = ref({
		id:'',
    kd_code:'',
    kd_name:''
	})
	const wlData = ref([])
	const onWlData = async () => {
	  const data= await wlSendInfoAPI(form.value)
	  console.log(data)
	  wlData.value = data
	}



	const kdnameRules = ref({
		kd_name: [{
			required: true,
			trigger: 'blur',
			message: '物流公司必填'
		}],
		kd_code: [{
			required: true,
			trigger: 'blur',
			message: '物流单号必填'
		}]
	})

	// 获得 Ref 实例
	const levelRef = ref(null)
	// 取消
	const btnCancel = () => {
		dialogShow.value = false
	}

	// 确认
	const btnOk = () => {
		levelRef.value.validate(async (valid) => {
			if (!valid) return
			try {
				// 触发添加 api
				console.log(Number(form.value.id))
				if (Number(form.value.id)) {
					await saveDeilverAPI(form.value)
					ElMessage.success('发货成功')
				}
				props.onDetail()
			} catch (error) {
				console.log(error)
			} finally {
				dialogShow.value = false
			}
		})
	}
</script>

<style>

  .allInfo .item{
    padding-bottom: 20px;
    border-bottom:1px solid #eee;
    margin-bottom: 20px;
    }
    .allInfo .item p{
      padding-bottom: 10px;
    }
</style>
