import request from '@/utils/request'

// 海鲜卡列表
export const cardListAPI = (data) => {
  return request({
    url: '/cardList',
    method: 'POST',
    data
  })
}

// 详情
export const cardDetailsAPI = (data) => {
  return request({
    url: '/cardhtInfo',
    method: 'POST',
    data
  })
}

// 导入
export const ExcelImportAPI = (dataset) => {
  return request({
    url: '/excel_import',
    method: 'POST',
    data: {
      dataset
    }
  })
}

// 详情
export const detailAPI = (params) => {
  return request({
    url: '/carddetaillist',
    method: 'GET',
    params
  })
}

// 发货-填写单号
export const saveDeilverAPI = (data) => {
  return request({
    url: '/savedeilver',
    method: 'POST',
    data
  })
}

// 添加海鲜卡
export const addCardAPI = (data) => {
  return request({
    url: '/cardAdd',
    method: 'POST',
    data
  })
}

// 编辑海鲜卡
export const saveCardAPI = (data) => {
  return request({
    url: '/savecard',
    method: 'POST',
    data
  })
}

// 导出
export const reportRecordAPI = (params) => {
  return request({
    url: '/reportCard',
    method: 'GET',
    params
  })
}



// 商品列表信息
export const goodsListAPI = (data) => {
  return request({
    url: '/goodsList',
    method: 'POST',
    data
  })
}

// 添加商品信息
export const addGoodsAPI = (data) => {
  return request({
    url: '/goodsAdd',
    method: 'POST',
    data
  })
}

// 编辑商品
export const saveGoodsdAPI = (data) => {
  return request({
    url: '/savegoods',
    method: 'POST',
    data
  })
}
// 编辑商品--上家下架
export const updateGoodsdAPI = (data) => {
  return request({
    url: '/updateGoods',
    method: 'POST',
    data
  })
}

// 查询物流西悉尼--公司
export const wlInfoAPI = (data) => {
  return request({
    url: '/wl_list',
    method: 'POST',
    data
  })
}

// 查询物流西悉尼--物流哥哥节点
export const wlSendInfoAPI = (data) => {
  return request({
    url: '/search_wl_info',
    method: 'POST',
    data
  })
}
